$font-family: Montserrat, 'Helvetica Neue', sans-serif;
$color-navbar: #333333;
$actionColor: #757575;
$color-text-label: #2b2b2b;
$color-text-dark: #000000;
$customerColor: #4273cb;
$color-text-muted: #aaaaaa;
//colors
$col_ebl_p_orange: #ff893d;
$col_ebl_p_darkgrey: #333333;
$col_ebL_p_lightgrey: #666666;

$col_ebl_s_darkblack: #000000;
$col_ebl_s_eblblack: #141414;
$col_ebl_s_20black: #d8d8d8;
$col_ebl_s_10black: #efefef;
$col_ebl_s_05black: #f5f5f5;

$md-accent: (
  50: #f9f4ed,
  100: #efe5d3,
  200: #e5d3b6,
  300: #dbc199,
  400: #d3b483,
  500: #cba76d,
  600: #c69f65,
  700: #be965a,
  800: #b88c50,
  900: #ac7c3e,
  A100: #fffefc,
  A200: #ffe7c9,
  A400: #ffd096,
  A700: #ffc47d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-warn: (
  50: #fdf5f5,
  100: #fbe6e6,
  200: #f8d6d6,
  300: #f5c6c6,
  400: #f2b9b9,
  500: #f0adad,
  600: #eea6a6,
  700: #ec9c9c,
  800: #e99393,
  900: #e58383,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff0f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-primary: (
  50: #e8eef9,
  100: #c6d5ef,
  200: #a1b9e5,
  300: #7b9ddb,
  400: #5e88d3,
  500: #4273cb,
  600: #3c6bc6,
  700: #3360be,
  800: #2b56b8,
  900: #1d43ac,
  A100: #e4eaff,
  A200: #b1c3ff,
  A400: #7e9cff,
  A700: #6489ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
