@import '../variables';

.content {
  height: 100%;
}
.header {
  background-color: $col_ebl_p_darkgrey;
  padding-top: 0;
  display: flex;
  flex-direction: row;
}
.container {
  height: calc(100% - 75px);
  background-color: $col_ebl_s_05black;
}

.ebl-logo {
  height: 40px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.title {
  padding-left: 20px;

  h1 {
    color: $col_ebl_p_orange;
  }
}

.div-left {
  border: 1px solid $col_ebl_s_20black;
  border-radius: 5px;
  background-color: white;
  //height: calc(100vh - 94px);
  width: 50%;
  padding: 10px;
  margin: 10px;
  float: left;
}

.div-right {
  border: 1px solid $col_ebl_s_20black;
  border-radius: 5px;
  background-color: white;
  //height: calc(100vh - 159px);
  width: 35%;
  padding: 10px;
  margin: 10px 10px 0 0;
  float: right;
}

.eblBtn {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  background-color: $col_ebl_p_orange;
  color: white;
  border-color: $col_ebl_s_20black;
  border-width: 0;
  border-radius: 5px;
  height: 30px;
}

table {
  margin: 0;
  border-spacing: 0;
}

td,
th {
  padding-right: 20px;
  border: solid $col_ebl_s_20black;
  border-width: 1px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin: 0;
  padding: 5px;
}

th {
  background-color: $col_ebl_p_darkgrey;
  color: white;
}
